/**
 * Calculates the SHA-256 hash of an ArrayBuffer and returns it as a hexadecimal string.
 *
 * @param arrayBuffer - The ArrayBuffer representing the content of the File object.
 * @returns A Promise that resolves to the SHA-256 hash as a hexadecimal string.
 */
export async function calculateSHA256Hash(
  arrayBuffer: ArrayBuffer
): Promise<string> {
  const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');

  return hashHex;
}