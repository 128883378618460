import React from "react";
import { NewAPIMentoringSheet, WithId } from "types";

const AppContext = React.createContext<{
  draftMentoringSheet: WithId<NewAPIMentoringSheet> | null;
  setDraftMentoringSheet: React.Dispatch<React.SetStateAction<WithId<NewAPIMentoringSheet> | null>>
}>({
  draftMentoringSheet: null,
  setDraftMentoringSheet: () => { },
});

export const useAppContext = () => React.useContext(AppContext);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [draftMentoringSheet, setDraftMentoringSheet] = React.useState<WithId<NewAPIMentoringSheet> | null>(null);
  return (
    <AppContext.Provider
      value={{
        draftMentoringSheet,
        setDraftMentoringSheet,

      }}
    >{children}</AppContext.Provider>
  )
}