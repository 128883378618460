import { AuthError, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from 'initFirebase';
import { useState } from 'react';

export const useResetPassword = () => {
  const [status, setStatus] = useState<
    'idle' | 'pending' | 'succeeded' | 'rejected'
  >('idle');
  const [error, setError] = useState<string | null>(null);
  const sendResetEmail = async (email: string) => {
    setStatus('pending');
    try {
      await sendPasswordResetEmail(auth, email);
      setStatus('succeeded');
    } catch (e) {
      setStatus('rejected');
      const errorCode = (e as AuthError).code;
      switch (errorCode) {
        case 'auth/invalid-email':
          setError('不正なメールアドレスです');
          break;
        case 'auth/user-not-found':
          setError('ユーザーが見つかりません');
          break;
        default:
          setError('問題が発生しました');
          break;
      }
    } finally {
    }
  };

  const dismissError = () => {
    setError(null);
    setStatus('idle');
  };

  return { sendResetEmail, status, error, dismissError };
};
