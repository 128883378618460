import { Box } from '@mui/material';
import { TabMenus } from './UserTabs';
import { SelectClient } from './SelectClient';
import { useLocation } from 'react-router-dom';
import React from 'react';
import { useAppSelector } from 'app/hooks';
import { selectFamily, selectFamilyClients } from 'features';

export const TabMenuWithSelectClient = ({
  viewType,
}: {
  viewType: 'client' | 'family';
}) => {
  const pathname = useLocation().pathname;
  const family = useAppSelector(selectFamily);
  const familyClients = useAppSelector(selectFamilyClients);

  return (
    <Box
      sx={{
        display: 'flex',
        borderBottom: 'solid 1px #ddd',
        alignItems: 'center',
        mt: { xs: 0, sm: 0.5 },
      }}
    >
      {family ? <SelectClient clients={familyClients} family={family} /> : null}

      <TabMenus initialTabValue={pathname} viewType={viewType} />
    </Box>
  );
};
