import { API_URL } from 'config';
import { InvestmentStrategy, WithId } from 'types';

export const getInvestmentStrategies = async ({ token }: { token: string }) => {
  const response = await fetch(`${API_URL}/investment-strategies`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<WithId<InvestmentStrategy>[]>;
};

export const putInvestmentStrategy = async ({
  id,
  token,
  data,
}: {
  id: string;
  token: string;
  data: InvestmentStrategy;
}) => {
  const response = await fetch(`${API_URL}/investment-strategiess/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<WithId<InvestmentStrategy>>;
};
