import {
  AuthError,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { auth } from 'initFirebase';
import { useState } from 'react';

export const useChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [sending, setSending] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [reauthRequired, setReauthRequired] = useState<boolean>(false);

  const onChangeCurrentPassword = (value: string) => {
    setCurrentPassword(value);
    setErrorMessage('');
  };

  const onChangeNewPassword = (value: string) => {
    setNewPassword(value);
    setErrorMessage('');
  };

  const attemptChangePassword = async () => {
    if (!newPassword) {
      setErrorMessage('新しいパスワードを入力してください');
      return;
    }

    if (newPassword.length < 6) {
      setErrorMessage('新しいパスワードは6文字以上で入力してください');
      return;
    }

    try {
      setSending(true);
      const user = auth.currentUser;
      if (!user) {
        setErrorMessage('ユーザーが見つかりません');
        return;
      }

      // 新しいパスワードで更新を試みる
      await updatePassword(user, newPassword);
      setSuccess(true);
    } catch (e) {
      const errorCode = (e as AuthError).code;
      if (errorCode === 'auth/requires-recent-login') {
        // 再認証が必要
        setReauthRequired(true);
      } else if (errorCode === 'auth/weak-password') {
        setErrorMessage('新しいパスワードが弱すぎます');
      } else {
        setErrorMessage('問題が発生しました');
      }
    } finally {
      setSending(false);
    }
  };

  const reauthenticateAndChangePassword = async () => {
    if (!currentPassword) {
      setErrorMessage('現在のパスワードを入力してください');
      return;
    }

    try {
      setSending(true);
      const user = auth.currentUser;
      if (!user || !user.email) {
        setErrorMessage('ユーザーが見つかりません');
        return;
      }

      // 現在のパスワードで再認証
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      await reauthenticateWithCredential(user, credential);

      // 再度パスワードの更新を試みる
      await updatePassword(user, newPassword);
      setSuccess(true);
      setReauthRequired(false);
    } catch (e) {
      const errorCode = (e as AuthError).code;
      if (errorCode === 'auth/wrong-password') {
        setErrorMessage('現在のパスワードが間違っています');
      } else if (errorCode === 'auth/weak-password') {
        setErrorMessage('新しいパスワードが弱すぎます');
      } else {
        setErrorMessage('問題が発生しました');
      }
    } finally {
      setSending(false);
    }
  };

  return {
    currentPassword,
    newPassword,
    sending,
    success,
    reauthRequired,
    onChangeCurrentPassword,
    onChangeNewPassword,
    attemptChangePassword,
    reauthenticateAndChangePassword,
    errorMessage,
  };
};
