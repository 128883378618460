import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  LinearProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import { Delete, InsertDriveFile } from '@mui/icons-material';

import {
  MentoringSheetFileWithTransferData,
  WithId,
  WithTimestamp,
} from 'types';

export const ReferenceFilesUpload = ({
  sheetFiles,
  uploadFile,
  getDownloadLink,
  removeFile,
}: {
  sheetFiles: WithTimestamp<WithId<MentoringSheetFileWithTransferData>>[];
  uploadFile: (file: File) => Promise<void>;
  getDownloadLink: (file_id: string) => Promise<string>;
  removeFile: (file_id: string) => Promise<void>;
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      handleFiles(event.target.files);
      event.target.value = '';
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleFiles(event.dataTransfer.files);
    setIsDragging(false);
  };

  const handleFiles = (fileList: FileList) => {
    const newFiles: File[] = [];
    Array.from(fileList).forEach(file => {
      if (file.size > 20 * 1024 * 1024) {
        alert(
          `${file.name} はファイル上限が超えているためアップロードできません`
        );
        return;
      }
      const duplicate = sheetFiles.find(f => f.file_name === file.name);
      if (duplicate) {
        const overwrite = window.confirm(
          `同じ名前のファイル ${file.name} は既にアップロードされています。上書きしますか？`
        );
        if (overwrite) {
          newFiles.push(file);
        }
      } else {
        newFiles.push(file);
      }
    });
    newFiles.forEach(uploadFile);
  };

  const handleDownloadFile = async (
    file: WithTimestamp<WithId<MentoringSheetFileWithTransferData>>
  ) => {
    try {
      const downloadUrl = await getDownloadLink(file.id);
      const extension = file.file_name.split('.').pop()?.toLowerCase();

      if (extension && ['pdf', 'jpg', 'jpeg', 'png'].includes(extension)) {
        window.open(downloadUrl, '_blank', 'noopener noreferrer');
      } else {
        const fileResponse = await fetch(downloadUrl);

        if (!fileResponse.ok) {
          throw new Error('ファイルの取得に失敗しました');
        }

        const blob = await fileResponse.blob();
        const blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = file.file_name;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl); // Release the Blob URL
      }
    } catch (err) {
      console.error(err);
      alert('ファイルのダウンロードに失敗しました');
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const getIconForFile = (fileName: string): string | null => {
    const extension = fileName.split('.').pop()?.toLowerCase() ?? '';
    switch (extension) {
      case 'xls':
      case 'xlsx':
        return '/xlsx_icon.png';

      case 'doc':
      case 'docx':
        return '/docx_icon.png';

      case 'ppt':
      case 'pptx':
        return '/pptx_icon.png';

      case 'pdf':
        return '/pdf_icon.png';

      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        border: '2px solid #ddd',
        p: 2,
        borderRadius: '6px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        gap: 2,
      }}
    >
      {sheetFiles.length > 0 && (
        <Box sx={{ mt: 1, flex: { sm: 3 }, width: { xs: '100%', sm: 'auto' } }}>
          <List>
            {sheetFiles.map(sheetFile => (
              <ListItem key={sheetFile.id}>
                <ListItemIcon>
                  {getIconForFile(sheetFile.file_name) ? (
                    <img
                      src={getIconForFile(sheetFile.file_name)!}
                      alt={`${sheetFile.file_name} icon`}
                      style={{
                        width: sheetFile.file_name.endsWith('.pdf') ? 20 : 26,
                      }}
                    />
                  ) : (
                    <InsertDriveFile />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Button
                      onClick={() => handleDownloadFile(sheetFile)}
                      sx={{ textTransform: 'none', textAlign: 'left' }}
                    >
                      {sheetFile.file_name}
                    </Button>
                  }
                />
                <ListItemSecondaryAction>
                  {sheetFile.status === 'uploaded' ? (
                    <IconButton
                      edge="end"
                      onClick={() => removeFile(sheetFile.id)}
                    >
                      <Delete />
                    </IconButton>
                  ) : sheetFile.status === 'pending' ? (
                    sheetFile.error ? (
                      <Typography color="error">{sheetFile.error}</Typography>
                    ) : (
                      <LinearProgress
                        variant="determinate"
                        value={(sheetFile.upload_progress ?? 0) * 100}
                        sx={{ width: 100, mr: 2 }}
                      />
                    )
                  ) : null}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      <Box
        sx={{
          borderRadius: '6px',
          m: { xs: 0, sm: 1 },
          p: 4,
          textAlign: 'center',
          backgroundColor: isDragging ? '#ddd' : '#f2f2f2',
          width: { xs: '100%', sm: 'auto' },
          flex: { sm: 2 },
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          multiple
          onChange={handleFileSelect}
          style={{ display: 'none' }}
          id="reference-files-input"
        />
        <label htmlFor="reference-files-input">
          <Button variant="contained" color="primary" component="span">
            ファイルを選択
          </Button>
        </label>
        <Typography variant="body2" sx={{ my: 1 }}>
          または
        </Typography>
        <Typography variant="body2">
          ファイルをここにドラッグ＆ドロップ
          <br />
          (1ファイルあたり20MBまで)
        </Typography>
      </Box>
    </Box>
  );
};
