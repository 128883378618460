import { Loading, TabMenuWithSelectClient } from 'components';
import { Outlet, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  fetchAssetsByClientIdAsync,
  fetchMentoringSheetsByClientIdAsync,
  selectClient,
  selectClientStatus,
  selectFamilyError,
  selectFamilyStatus,
} from 'features';
import { useFetchDataForTabs } from 'hooks';
import { useEffect } from 'react';

export const ClientLayout = () => {
  const dispatch = useAppDispatch();
  const { clientId } = useParams() as { clientId: string };
  const clientStatus = useAppSelector(selectClientStatus);
  const client = useAppSelector(selectClient);
  const familyStatus = useAppSelector(selectFamilyStatus);
  const familyError = useAppSelector(selectFamilyError);
  useEffect(() => {
    dispatch(fetchAssetsByClientIdAsync({ id: clientId }));
    dispatch(fetchMentoringSheetsByClientIdAsync({ id: clientId }));
  }, [dispatch, clientId]);
  useFetchDataForTabs(clientId);

  // ローディングとエラーハンドリング
  if (clientStatus === 'idle') {
    return <Loading />;
  }

  if (clientStatus === 'rejected') {
    return <div>クライアントの取得に失敗しました。</div>;
  }

  if (client?.familyId) {
    if (familyStatus === 'rejected') {
      return <div>{familyError || 'ファミリーの取得に失敗しました。'}</div>;
    }
  }
  return (
    <>
      <TabMenuWithSelectClient viewType="client" />
      {clientStatus === 'pending' ? <Loading /> : <Outlet />}
    </>
  );
};
