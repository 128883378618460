import {
  MentoringSheetFileWithUploadUrl,
  MentoringSheetFileBase,
  WithTimestamp,
  WithId,
  MentoringSheetFileWithDownloadUrl,
} from 'types';
import { API_URL } from '../config';

export const getMentoringSheetFiles = async ({
  client_id,
  mentoring_sheet_id,
  token,
}: {
  client_id: string;
  mentoring_sheet_id: string;
  token: string;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}/files`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    const text = await response.text();
    throw new Error(text || 'エラーが発生しました');
  }
  return response.json() as Promise<
    WithTimestamp<WithId<MentoringSheetFileBase>>[]
  >;
};

export const getMentoringSheetFileById = async ({
  client_id,
  mentoring_sheet_id,
  file_id,
  token,
}: {
  client_id: string;
  mentoring_sheet_id: string;
  file_id: string;
  token: string;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}/files/${file_id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    const text = await response.text();
    throw new Error(text || 'エラーが発生しました');
  }
  return response.json() as Promise<
    WithTimestamp<WithId<MentoringSheetFileWithDownloadUrl>>
  >;
};

export const postMentoringSheetFile = async ({
  client_id,
  mentoring_sheet_id,
  token,
  ...data
}: {
  client_id: string;
  mentoring_sheet_id: string;
  token: string;
} & MentoringSheetFileBase) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}/files`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    const text = await response.text();
    throw new Error(text || 'エラーが発生しました');
  }

  return response.json() as Promise<
    WithTimestamp<WithId<MentoringSheetFileWithUploadUrl>>
  >;
};

export const putMentoringSheetFile = async ({
  client_id,
  mentoring_sheet_id,
  file_id,
  token,
  ...data
}: {
  client_id: string;
  mentoring_sheet_id: string;
  file_id: string;
  token: string;
} & MentoringSheetFileBase) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}/files/${file_id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    const text = await response.text();
    throw new Error(text || 'エラーが発生しました');
  }

  return response.json() as Promise<
    WithTimestamp<WithId<MentoringSheetFileWithUploadUrl>>
  >;
};

export const patchMentoringSheetFile = async ({
  client_id,
  mentoring_sheet_id,
  file_id,
  file_name,
  sha256_hash,
  filesize_bytes,
  mime_type,
  status,
  token,
}: {
  client_id: string;
  mentoring_sheet_id: string;
  file_id: string;
  token: string;
} & Partial<{
  file_name: string;
  sha256_hash: string;
  filesize_bytes: number;
  mime_type: string;
  status: 'pending' | 'uploaded';
}>) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}/files/${file_id}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      // stringify removes all undefined fields
      body: JSON.stringify({
        file_name,
        sha256_hash,
        filesize_bytes,
        status,
        mime_type,
      }),
    }
  );

  if (!response.ok) {
    const text = await response.text();
    throw new Error(text || 'エラーが発生しました');
  }

  return response.json() as Promise<
    WithTimestamp<WithId<MentoringSheetFileBase>>
  >;
};

export const deleteMentoringSheetFile = async ({
  client_id,
  mentoring_sheet_id,
  file_id,
  token,
}: Partial<{
  client_id: string;
  mentoring_sheet_id: string;
  file_id: string;
  token: string;
}>) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}/files/${file_id}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const text = await response.text();
    throw new Error(text || 'エラーが発生しました');
  }
};
