import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  fetchClientByIdAsync,
  fetchClientsByFamilyIdAsync,
  fetchFamilyByIdAsync,
  selectClient,
  selectClientStatus,
  selectFamily,
} from 'features';

export const useFetchDataForTabs = (clientId: string) => {
  const dispatch = useAppDispatch();
  const client = useAppSelector(selectClient);
  const familyId = client?.familyId;

  useEffect(() => {
    dispatch(fetchClientByIdAsync({ id: clientId }));
  }, [dispatch, clientId]);

  useEffect(() => {
    if (familyId) {
      dispatch(fetchClientsByFamilyIdAsync({ familyId }));
      dispatch(fetchFamilyByIdAsync({ id: familyId }));
    }
  }, [dispatch, familyId]);
};
