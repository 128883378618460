import { createSlice } from '@reduxjs/toolkit';
import { NewApiClient } from 'types';
import type { RootState } from '../../app/store';
import { createAppAsyncThunk } from '../../app/withTypes';
import { getToken } from 'api';
import { getClients } from 'api';

type FamilyClientsState = {
  clients: NewApiClient[];
  status: 'idle' | 'pending' | 'succeeded' | 'rejected';
  error: string | null;
};

const initialState: FamilyClientsState = {
  clients: [],
  status: 'idle',
  error: null,
};

export const fetchClientsByFamilyIdAsync = createAppAsyncThunk(
  'familyClients/fetchClientsByFamilyId',
  async ({ familyId }: { familyId: string }) => {
    const token = await getToken();
    const response = await getClients({ token, familyId });
    return response;
  }
);

export const familyClientSlice = createSlice({
  name: 'familyClients',
  initialState: initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
    dismissError(state) {
      state.error = null;
      state.status = 'succeeded';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchClientsByFamilyIdAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(fetchClientsByFamilyIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clients = action.payload;
      })
      .addCase(fetchClientsByFamilyIdAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      });
  },
});

export const selectFamilyClients = (state: RootState) =>
  state.familyClient.clients;
export const selectFamilyClientsStatus = (state: RootState) =>
  state.familyClient.status;
export const selectFamilyClientsError = (state: RootState) =>
  state.familyClient.error;
