import { createSlice } from '@reduxjs/toolkit';
import { DraftClient, NewApiClient } from 'types';
import type { RootState } from '../../app/store';
import { createAppAsyncThunk } from '../../app/withTypes';
import { getToken } from 'api';
import { getClientById, getClients, putClientById } from 'api';

type ClientsState = {
  client: NewApiClient | null;
  status: 'idle' | 'pending' | 'succeeded' | 'rejected';
  error: string | null;
};

const initialState: ClientsState = {
  client: null,
  status: 'idle',
  error: null,
};

export const fetchClientByIdAsync = createAppAsyncThunk(
  'client/fetchClientById',
  async ({ id }: { id: string }) => {
    const token = await getToken();
    const client = await getClientById({ id, token });
    return client;
  }
);

export const replaceClientByIdAsync = createAppAsyncThunk(
  'client/replaceClientById',
  async ({ id, data }: { id: string; data: DraftClient }) => {
    const token = await getToken();
    const response = await putClientById({ id, data, token });
    return response;
  }
);

export const clientSlice = createSlice({
  name: 'client',
  initialState: initialState,
  reducers: {
    reset(state) {
      return initialState;
    },

    dismissError(state) {
      state.error = null;
      state.status = 'succeeded';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchClientByIdAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(fetchClientByIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.client = action.payload;
      })
      .addCase(fetchClientByIdAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      });
  },
});

export const selectClientStatus = (state: RootState) => state.client.status;
export const selectClient = (state: RootState) => state.client.client;
export const selectClientError = (state: RootState) => state.client.error;
