import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getAssetsByFamilyId,
  getFamilies,
  getFamilyAssetHistoryAggregationByAssetType,
  getFamilyFinancialSummary,
} from 'api';
import { RootState } from 'app/store';
import { getToken } from 'api';
import {
  FamilyAssetHistoryAggregationByAssetType,
  FinancialSummary,
  IAsset,
} from 'types';
type FamilyState = {
  status: 'idle' | 'pending' | 'succeeded' | 'rejected';
  error: string | null;
  assets: IAsset[];
  summary: FinancialSummary | null;
  historicalAggregationByAssetTypeMonthly: FamilyAssetHistoryAggregationByAssetType[];
};

const initialState: FamilyState = {
  status: 'idle',
  error: null,
  assets: [],
  summary: null,
  historicalAggregationByAssetTypeMonthly: [],
};

export const fetchFamilyAssetsByIdAsync = createAsyncThunk(
  'family-asset/fetchFamilyAssetsById',
  async ({ id }: { id: string }) => {
    const token = await getToken();
    const twoYearsAgo = new Date(
      new Date().setFullYear(new Date().getFullYear() - 2)
    ).toISOString();
    const today = new Date().toISOString();
    const [assets, summary, historicalAggregationByAssetType] =
      await Promise.all([
        getAssetsByFamilyId({ id, token }),
        getFamilyFinancialSummary({ id, token }),
        getFamilyAssetHistoryAggregationByAssetType({
          id: id,
          token: token,
          freq: 'monthly',
          start_at: twoYearsAgo,
          end_at: today,
        }),
      ]);
    return { assets, summary, historicalAggregationByAssetType };
  }
);

export const familyAssetSlice = createSlice({
  name: 'familyAsset',
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder

      .addCase(fetchFamilyAssetsByIdAsync.pending, state => {
        state.status = 'pending';
      })
      .addCase(fetchFamilyAssetsByIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.assets = action.payload.assets;
        state.summary = action.payload.summary;
        state.historicalAggregationByAssetTypeMonthly =
          action.payload.historicalAggregationByAssetType;
      })
      .addCase(fetchFamilyAssetsByIdAsync.rejected, state => {
        state.status = 'rejected';
        state.error = 'エラーが発生しました';
      });
  },
});

export const selectFamilyAssetStatus = (state: RootState) =>
  state.familyAsset.status;
export const selectFamilyAssetError = (state: RootState) =>
  state.familyAsset.error;
export const selectFamilyAssets = (state: RootState) =>
  state.familyAsset.assets;
export const selectFamilyFinancialSummary = (state: RootState) =>
  state.familyAsset.summary;
export const selectFamilyHistoricalAggregationByAssetTypeMonthly = (
  state: RootState
) => state.familyAsset.historicalAggregationByAssetTypeMonthly;
