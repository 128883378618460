import React from 'react';
import {
  Box,
  Stack,
  Link,
  Typography,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useChangePassword } from 'hooks';
import { LogoTop } from 'parts/LogoTop';

export const ChangePasswordPage = () => {
  const {
    currentPassword,
    newPassword,
    sending,
    success,
    reauthRequired,
    onChangeCurrentPassword,
    onChangeNewPassword,
    attemptChangePassword,
    reauthenticateAndChangePassword,
    errorMessage,
  } = useChangePassword();

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 8 }}>
      <Stack
        spacing={4}
        alignItems="center"
        justifyContent="center"
        sx={{ maxWidth: 'sm' }}
      >
        <LogoTop />
        <Typography variant="h6" component="h2" sx={{ mt: 8, mb: 4 }}>
          パスワードの変更
        </Typography>
        {reauthRequired && (
          <Typography variant="caption" sx={{ color: 'red' }}>
            現在のパスワードを入力してください。
          </Typography>
        )}
        {success ? (
          <>
            <Typography sx={{ mt: 2, color: 'green' }}>
              パスワードが正常に変更されました。
            </Typography>
            <Link component={RouterLink} to="/signin" variant="caption">
              ログイン画面に戻る
            </Link>
          </>
        ) : (
          <>
            {reauthRequired && (
              <TextField
                label="現在のパスワード"
                type="password"
                value={currentPassword}
                onChange={e => onChangeCurrentPassword(e.target.value)}
                error={Boolean(errorMessage)}
                helperText={errorMessage}
                size="small"
              />
            )}
            <TextField
              label="新しいパスワード"
              type="password"
              value={newPassword}
              onChange={e => onChangeNewPassword(e.target.value)}
              error={Boolean(errorMessage)}
              helperText={errorMessage}
              size="small"
            />
            <Button
              color="secondary"
              variant="contained"
              size="large"
              onClick={
                reauthRequired
                  ? reauthenticateAndChangePassword
                  : attemptChangePassword
              }
              disabled={sending}
            >
              {sending ? (
                <CircularProgress sx={{ color: 'white' }} />
              ) : reauthRequired ? (
                '再認証して変更する'
              ) : (
                '変更する'
              )}
            </Button>
          </>
        )}
      </Stack>
    </Box>
  );
};
