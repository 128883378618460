import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getFamilies, getFamilyById, postFamily, putFamilyById } from 'api';
import { RootState } from 'app/store';
import { getToken } from 'api';
import { Family } from 'types';

type FamilyState = {
  status: 'idle' | 'pending' | 'succeeded' | 'rejected';
  error: string | null;
  family: Family | null;
};

const initialState: FamilyState = {
  family: null,
  status: 'idle',
  error: null,
};

export const fetchFamilyByIdAsync = createAsyncThunk(
  'family/fetchFamilyById',
  async ({ id }: { id: string }) => {
    const token = await getToken();
    const [family] = await Promise.all([getFamilyById({ id, token })]);
    return { family };
  }
);

export const replaceFamily = createAsyncThunk(
  'family/replaceFamily',
  async ({ id, data }: { id: string; data: Family }) => {
    const token = await getToken();
    const response = await putFamilyById({ id, data, token });
    return response;
  }
);

export const familySlice = createSlice({
  name: 'family',
  initialState,
  reducers: {
    set(state, action: PayloadAction<Family>) {
      state.family = action.payload;
    },
    reset(state) {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: builder => {
    builder

      .addCase(fetchFamilyByIdAsync.pending, state => {
        state.status = 'pending';
      })
      .addCase(fetchFamilyByIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.family = action.payload.family;
      })
      .addCase(fetchFamilyByIdAsync.rejected, state => {
        state.status = 'rejected';
      })

      .addCase(replaceFamily.pending, state => {
        state.status = 'pending';
      })
      .addCase(replaceFamily.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const id = action.meta.arg.id;
        const family = action.payload;
      })
      .addCase(replaceFamily.rejected, state => {
        state.status = 'rejected';
      });
  },
});

export const selectFamiliesStatus = (state: RootState) => state.family.status;
export const selectFamilies = (state: RootState) => state.family.status;
export const selectFamily = (state: RootState) => state.family.family;
export const selectFamilyStatus = (state: RootState) => state.family.status;
export const selectFamilyError = (state: RootState) => state.family.error;
