import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DashboardPresentation } from 'components/Dashboard';
import { Loading } from 'components/Loading';
import {
  clientInvestmentStrategySlice,
  fetchClientInvestmentStrategiesAsync,
  fetchInvestmentStrategiesAsync,
  selectClient,
  selectClientFinancialSummary,
  selectClientInvestmentStrategies,
  selectClientInvestmentStrategyStatus,
  selectClientStatus,
  selectInvestmentStrategies,
  selectInvestmentStrategyStatus,
  selectMentoringSheets,
} from 'features';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const DashboardPage = () => {
  const dispatch = useAppDispatch();
  const { clientId } = useParams() as { clientId: string };
  const clientStatus = useAppSelector(selectClientStatus);
  const client = useAppSelector(selectClient);
  const mentoringSheets = useAppSelector(selectMentoringSheets);
  const financialSummary = useAppSelector(selectClientFinancialSummary);
  const investmentStrategyStatus = useAppSelector(
    selectInvestmentStrategyStatus
  );
  const investmentStrategies = useAppSelector(selectInvestmentStrategies);

  const clientInvestmentStrategyStatus = useAppSelector(
    selectClientInvestmentStrategyStatus
  );
  const clientInvestmentStrategies = useAppSelector(
    selectClientInvestmentStrategies
  );
  const sheet = mentoringSheets[0];

  useEffect(() => {
    if (investmentStrategyStatus === 'idle') {
      dispatch(fetchInvestmentStrategiesAsync());
    }
  }, [dispatch, investmentStrategyStatus]);

  useEffect(() => {
    if (clientInvestmentStrategyStatus === 'idle') {
      dispatch(fetchClientInvestmentStrategiesAsync({ client_id: clientId }));
    }
  }, [dispatch, clientId]);
  useEffect(() => {
    dispatch(clientInvestmentStrategySlice.actions.reset());
  }, [dispatch, clientId]);

  if (clientStatus === 'pending' || !client || !sheet || !financialSummary) {
    return <Loading />;
  }
  if (clientStatus === 'rejected') {
    return (
      <div>
        データの取得に失敗しました。お手数ですが、時間を置いてアクセスしてください。
      </div>
    );
  }
  return (
    <>
      <DashboardPresentation
        sheet={sheet}
        clientName={client?.name ?? 'お客'}
        totalMarketValueDomestic={financialSummary.marketValueDomestic ?? 0}
        personalizedInsightSpeakerDeckIFrame={
          investmentStrategies[0]?.speakerDeckIFrame ?? ''
        }
        commonInsightSpeakerDeckIFrame={
          clientInvestmentStrategies[0]?.speakerDeckIFrame ?? ''
        }
      />
    </>
  );
};
