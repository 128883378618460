import React, { useState } from 'react';
import { Tab, Tabs, TabsProps } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditIcon from '@mui/icons-material/Edit';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface UserTabsProps extends TabsProps {
  initialTabValue?: string;
  viewType: 'client' | 'family';
}

export const TabMenus = ({ initialTabValue, viewType }: UserTabsProps) => {
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    navigate(newValue);
  };
  const { clientId, familyId } = useParams();
  const stemPath = clientId ? '/clients/' + clientId : '/families/' + familyId;
  const mentoringPath = stemPath + '/mentoring';
  const reportPath = stemPath + '/report';
  const iconStyle = {
    fontSize: 'body1.fontSize',
    verticalAlign: 'bottom',
    mr: '3px',
  };
  const tabStyleForMobile = { fontSize: 11, mx: 0.5, minWidth: 0, p: 0 };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tabMenus =
    viewType === 'client' ? ['dashbaord', 'mentoring', 'report'] : ['report'];
  return (
    <Tabs value={initialTabValue} onChange={handleChange}>
      {tabMenus.map(t => {
        switch (t) {
          case 'dashbaord':
            return isMobile ? (
              <Tab
                icon={<DashboardIcon />}
                label="ダッシュボード"
                value={stemPath}
                sx={tabStyleForMobile}
              />
            ) : (
              <Tab
                label={
                  <div>
                    <DashboardIcon sx={iconStyle} />
                    ダッシュボード
                  </div>
                }
                value={stemPath}
              />
            );
          case 'mentoring':
            return isMobile ? (
              <Tab
                icon={<EditIcon />}
                label="G-POP&reg;"
                value={mentoringPath}
                sx={tabStyleForMobile}
              />
            ) : (
              <Tab
                label={
                  <div>
                    <EditIcon sx={iconStyle} />
                    G-POP&reg;
                  </div>
                }
                value={mentoringPath}
              />
            );
          case 'report':
            return isMobile ? (
              <Tab
                icon={<ShowChartIcon />}
                label="レポート"
                value={reportPath}
                sx={tabStyleForMobile}
              />
            ) : (
              <Tab
                label={
                  <div>
                    <ShowChartIcon sx={iconStyle} />
                    レポート
                  </div>
                }
                value={reportPath}
              />
            );
        }
      })}
    </Tabs>
  );
};
