import { Box } from '@mui/material';

export const LogoTop = () => (
  <Box display="flex" justifyContent="center">
    <img
      width="40px"
      src={process.env.PUBLIC_URL + '/logo.svg'}
      style={{ marginRight: '10px' }}
      alt="logo"
    />
    <img width="180px" src={process.env.PUBLIC_URL + '/logoTypoNavy.svg'} />
  </Box>
);
