import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'components';
import { AppProvider, AuthProvider } from 'contexts';
import { theme } from 'createTheme';
import { AuthorizedLayout, ClientLayout, FamilyLayout } from 'layouts';
import {
  ChangePasswordPage,
  ClientRootPage,
  DashboardPage,
  FamilyReportPage,
  MentoringPage,
  ReportPage,
  ResetPasswordPage,
  SignInPage,
  UpdateEmailPage,
} from 'pages';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SnackbarProvider>
          <AppProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/signin" element={<SignInPage />} />
                <Route path="/reset-password" element={<ResetPasswordPage />} />
                <Route path="/" element={<AuthorizedLayout />}>
                  <Route
                    path="update-password"
                    element={<ChangePasswordPage />}
                  />
                  <Route path="update-email" element={<UpdateEmailPage />} />
                  <Route index element={<ClientRootPage />} />
                  <Route path="clients">
                    <Route path=":clientId" element={<ClientLayout />}>
                      <Route index element={<DashboardPage />} />
                      <Route path="mentoring" element={<MentoringPage />} />
                      <Route path="report" element={<ReportPage />} />
                    </Route>
                  </Route>
                  <Route path="families">
                    <Route path=":familyId" element={<FamilyLayout />}>
                      <Route path="report" element={<FamilyReportPage />} />
                    </Route>
                  </Route>
                </Route>
                <Route path="*" element={<div>404</div>} />
              </Routes>
            </BrowserRouter>
          </AppProvider>
        </SnackbarProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
