import { Box, Grid } from '@mui/material';

interface AlignCenterGridProps {
  children: React.ReactNode;
}
export const AlignCenterGrid: React.FC<AlignCenterGridProps> = ({
  children,
}) => (
  <Box
    display="flex"
    justifyContent="center"
    sx={{ backgroundColor: '#f7fcff', pt: 4 }}
  >
    <Grid>{children}</Grid>
  </Box>
);
