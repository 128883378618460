import { API_URL } from 'config';
import { ClientInvestmentStrategy, WithId } from 'types';

export const getClientInvestmentStrategies = async ({
  client_id,
  token,
}: {
  client_id: string;
  token: string;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/investment-strategies`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<WithId<ClientInvestmentStrategy>[]>;
};

export const putClientInvestmentStrategy = async ({
  client_id,
  id,
  token,
  data,
}: {
  client_id: string;
  id: string;
  token: string;
  data: ClientInvestmentStrategy;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/investment-strategiess/${id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<WithId<ClientInvestmentStrategy>>;
};
