import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { NewAPIMentoringSheet, WithTimestamp, WithId } from 'types';

export const SelectSheet = ({
  sheets,
  sheet,
  selectSheet,
}: {
  sheets: WithTimestamp<WithId<NewAPIMentoringSheet>>[];
  sheet: WithTimestamp<WithId<NewAPIMentoringSheet>>;
  selectSheet: (sheet: WithTimestamp<WithId<NewAPIMentoringSheet>>) => void;
}) => (
  <FormControl variant="standard" sx={{ minWidth: 165 }}>
    <InputLabel>ミーティング日</InputLabel>
    <Select
      value={sheet.id}
      onChange={e => {
        const selectedSheet = sheets.find(sheet => sheet.id === e.target.value);
        if (selectedSheet === undefined) {
          throw new Error('not found sheet');
        }
        selectSheet(selectedSheet);
      }}
    >
      {sheets.map(sheet => {
        const date = new Date(sheet.meetingDate);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const draftSuffix = sheet.isDraft ? ' (下書き)' : '';
        return (
          <MenuItem value={sheet.id} key={sheet.id}>
            {`${year}年${month}月${day}日${draftSuffix}`}
          </MenuItem>
        );
      })}
    </Select>
  </FormControl>
);
