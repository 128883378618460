import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts';

export const useRedirectIfSignedIn = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser === undefined || currentUser === null) {
      return;
    }
    const redirectPath = '/clients/' + currentUser.uid;
    navigate(redirectPath);
  }, [currentUser, navigate]);
};
