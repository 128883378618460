import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Loading, ReportPresentation } from 'components';
import {
  fetchFamilyAssetsByIdAsync,
  fetchFamilyByIdAsync,
  selectFamily,
  selectFamilyAssetError,
  selectFamilyAssets,
  selectFamilyAssetStatus,
  selectFamilyError,
  selectFamilyHistoricalAggregationByAssetTypeMonthly,
  selectFamilyStatus,
} from 'features';
import { useExchangeRates } from 'hooks';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const FamilyReportPage = () => {
  const dispatch = useAppDispatch();
  // get familyId from URL
  const params = useParams();
  const { familyId } = params as { familyId: string };
  const exchangeRates = useExchangeRates();
  const familyStatus = useAppSelector(selectFamilyStatus);
  const family = useAppSelector(selectFamily);
  const familyError = useAppSelector(selectFamilyError);

  const familyAssetStatus = useAppSelector(selectFamilyAssetStatus);
  const familyAssets = useAppSelector(selectFamilyAssets);
  const familyAssetError = useAppSelector(selectFamilyAssetError);
  const familyAssetHistoricalAggregationByAssetTypeMonthly = useAppSelector(
    selectFamilyHistoricalAggregationByAssetTypeMonthly
  );

  useEffect(() => {
    if (familyStatus === 'idle') {
      dispatch(fetchFamilyByIdAsync({ id: familyId }));
    }
  }, [dispatch, familyStatus]);

  useEffect(() => {
    if (familyAssetStatus === 'idle') {
      dispatch(fetchFamilyAssetsByIdAsync({ id: familyId }));
    }
  }, [dispatch, familyAssetStatus]);

  if (
    familyStatus === 'pending' ||
    !family ||
    familyAssetStatus === 'pending'
  ) {
    return <Loading />;
  }
  if (familyStatus === 'rejected') {
    return <div>{familyError}</div>;
  }
  if (familyAssetStatus === 'rejected') {
    return <div>{familyAssetError}</div>;
  }
  return (
    <ReportPresentation
      clientOrFamily={family}
      assets={familyAssets}
      barData={familyAssetHistoricalAggregationByAssetTypeMonthly}
      exchangeRates={exchangeRates}
    />
  );
};
