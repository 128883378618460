import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getInvestmentStrategies, getToken, putInvestmentStrategy } from 'api';
import { RootState } from 'app/store';
import { InvestmentStrategy, WithId } from 'types';

export const fetchInvestmentStrategiesAsync = createAsyncThunk(
  'investmentStrategy/fetchInvestmentStrategies',
  async () => {
    const token = await getToken();
    const response = await getInvestmentStrategies({ token });
    return response;
  }
);
export const replaceInvestmentStrategyAsync = createAsyncThunk(
  'investmentStrategy/replaceInvestmentStrategy',
  async ({ id, data }: { id: string; data: InvestmentStrategy }) => {
    const token = await getToken();
    const response = await putInvestmentStrategy({ id, token, data });
    return response;
  }
);

export const replaceInvestmentStrategyById = async ({
  id,
  token,
  data,
}: {
  id: string;
  token: string;
  data: InvestmentStrategy;
}) => {
  const response = await putInvestmentStrategy({ id, token, data });
  return response;
};

type InvestmentStrategyState = {
  investmentStrategies: WithId<InvestmentStrategy>[];
  status: 'idle' | 'pending' | 'succeeded' | 'rejected';
  error: string | null;
};

const initialState: InvestmentStrategyState = {
  investmentStrategies: [],
  status: 'idle',
  error: null,
};

export const investmentStrategySlice = createSlice({
  name: 'investmentStrategy',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchInvestmentStrategiesAsync.pending, state => {
        state.status = 'pending';
      })
      .addCase(fetchInvestmentStrategiesAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.investmentStrategies = action.payload;
      })
      .addCase(fetchInvestmentStrategiesAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(replaceInvestmentStrategyAsync.pending, state => {
        state.status = 'pending';
      })
      .addCase(replaceInvestmentStrategyAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const idx = state.investmentStrategies.findIndex(
          investmentStrategy => investmentStrategy.id === action.meta.arg.id
        );
        state.investmentStrategies = [
          ...state.investmentStrategies.slice(0, idx),
          action.payload,
          ...state.investmentStrategies.slice(idx + 1),
        ];
      })
      .addCase(replaceInvestmentStrategyAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      });
  },
});

export const selectInvestmentStrategyStatus = (state: RootState) =>
  state.investmentStrategy.status;

export const selectInvestmentStrategies = (state: RootState) =>
  state.investmentStrategy.investmentStrategies;

export const selectInvestmentStrategyError = (state: RootState) =>
  state.investmentStrategy.error;
