import { createSlice } from '@reduxjs/toolkit';
import { getFinancialProduct, getFinancialProducts, getToken } from 'api';
import { RootState } from 'app/store';
import { createAppAsyncThunk } from 'app/withTypes';
import { FinancialProductListItem, FinancialProductDetail } from 'types';

type FinancialProductState = {
  listStatus: 'idle' | 'pending' | 'succeeded' | 'rejected';
  listStatusCode: number | null;
  listError: string | null;
  products: FinancialProductListItem[];
  detailStatus: 'idle' | 'pending' | 'succeeded' | 'rejected';
  detailStatusCode: number | null;
  detailError: string | null;
  product: FinancialProductDetail | null;
};

const initialState: FinancialProductState = {
  listStatus: 'idle',
  listStatusCode: null,
  listError: null,
  products: [],
  detailStatus: 'idle',
  detailStatusCode: null,
  detailError: null,
  product: null,
};

export const fetchFinancialProductsAsync = createAppAsyncThunk(
  'financialProduct/fetchFinancialProducts',
  async () => {
    const token = await getToken();
    const response = await getFinancialProducts(token);
    return response;
  }
);

export const fetchFinancialProductAsync = createAppAsyncThunk(
  'financialProduct/fetchFinancialProduct',
  async ({ id }: { id: string }) => {
    const token = await getToken();
    const response = await getFinancialProduct({ id, token });
    return response;
  }
);

export const financialProductSlice = createSlice({
  name: 'financialProduct',
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchFinancialProductsAsync.pending, state => {
        state.listStatus = 'pending';
      })
      .addCase(fetchFinancialProductsAsync.fulfilled, (state, action) => {
        state.listStatus = 'succeeded';
        state.products = action.payload;
      })
      .addCase(fetchFinancialProductsAsync.rejected, state => {
        state.listStatus = 'rejected';
      })
      .addCase(fetchFinancialProductAsync.pending, state => {
        state.listStatus = 'pending';
      })
      .addCase(fetchFinancialProductAsync.fulfilled, (state, action) => {
        state.listStatus = 'succeeded';
        state.product = action.payload;
      })
      .addCase(fetchFinancialProductAsync.rejected, state => {
        state.listStatus = 'rejected';
      });
  },
});

export const selectFinancialProductStatus = (state: RootState) =>
  state.financialProduct.listStatus;

export const selectFinancialProducts = (state: RootState) =>
  state.financialProduct.products;
