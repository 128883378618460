import {
  Box,
  Button,
  CircularProgress,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useResetPassword } from 'hooks';
import { Link as RouterLink } from 'react-router-dom';
import { LogoTop } from 'parts/LogoTop';

export const ResetPasswordPage = () => {
  const { sendResetEmail, status, error, dismissError } = useResetPassword();
  const [email, setEmail] = useState<string>('');

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 8 }}>
      <Stack
        spacing={4}
        alignItems="center"
        justifyContent="center"
        sx={{ maxWidth: 'sm' }}
      >
        <LogoTop />
        <Typography variant="h6" component="h1">
          パスワードの再設定
        </Typography>
        <Typography variant="body1">
          ご登録のメールアドレスにパスワード再設定用のリンクを送信します。
        </Typography>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="メールアドレス"
            onChange={e => {
              setEmail(e.target.value);
              dismissError();
            }}
            variant="outlined"
            value={email}
            size="small"
            placeholder={'abc@example.com'}
            fullWidth
            helperText={error}
            error={status === 'rejected'}
            type="email"
          />
        </Box>
        {status === 'succeeded' ? (
          <Typography sx={{ mt: 16 }}>
            パスワードリセット用メールを送信しました。
            <code>noreply@system.pragmaworks.co.jp</code>
            からのメールが見つからない場合、スパムフォルダーもご確認ください。不明点がある場合、担当にご連絡ください。
          </Typography>
        ) : (
          <Button
            color="secondary"
            variant="contained"
            size="large"
            onClick={() => sendResetEmail(email)}
          >
            {status === 'pending' ? (
              <CircularProgress sx={{ color: 'white' }} />
            ) : (
              '送信する'
            )}
          </Button>
        )}
        <Link component={RouterLink} to="/signin" variant="caption">
          ログイン画面に戻る
        </Link>
      </Stack>
    </Box>
  );
};
