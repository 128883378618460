import React, { useEffect } from 'react';
import {
  Loading,
  ReportPresentation,
  TabMenuWithSelectClient,
} from 'components';
import { useExchangeRates } from 'hooks';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  clientAssetSlice,
  clientSlice,
  familySlice,
  fetchAssetsByClientIdAsync,
  fetchClientByIdAsync,
  fetchClientsByFamilyIdAsync,
  fetchFamilyByIdAsync,
  selectClient,
  selectClientAssets,
  selectClientAssetStatus,
  selectClientError,
  selectClientHistoricalAggregationByAssetTypeMonthly,
  selectClientStatus,
  selectFamily,
  selectFamilyClients,
  selectFamilyClientsStatus,
  selectFamilyStatus,
} from 'features';
import { useParams } from 'react-router-dom';

export const ReportPage = () => {
  const dispatch = useAppDispatch();
  const { clientId } = useParams() as { clientId: string };
  const exchangeRates = useExchangeRates();
  const clientStatus = useAppSelector(selectClientStatus);
  const clientError = useAppSelector(selectClientError);
  const client = useAppSelector(selectClient);
  const assetStatus = useAppSelector(selectClientAssetStatus);
  const assets = useAppSelector(selectClientAssets);
  const historicalAggregationByAssetTypeMonthly = useAppSelector(
    selectClientHistoricalAggregationByAssetTypeMonthly
  );

  if (clientStatus === 'pending' || !client || assetStatus === 'pending') {
    return <Loading />;
  }
  if (clientStatus === 'rejected' || assetStatus === 'rejected') {
    return (
      <div>
        {clientError ??
          'データの取得に失敗しました。お手数ですが、時間を置いてアクセスしてください。'}
      </div>
    );
  }

  return (
    <ReportPresentation
      clientOrFamily={client}
      assets={assets}
      barData={historicalAggregationByAssetTypeMonthly}
      exchangeRates={exchangeRates}
    />
  );
};
