import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ClientAssetHistoryAggregationByAssetType,
  FinancialSummary,
  IAsset,
  IManualInput,
} from 'types';
import type { RootState } from '../../app/store';
import { createAppAsyncThunk } from '../../app/withTypes';
import { getToken } from 'api';
import {
  deleteAsset,
  getAssetsByClientId,
  getClientAssetHistoryAggregationByAssetType,
  getClientFinancialSummary,
  postAsset,
  putAsset,
} from 'api';

type AssetState = {
  status: 'idle' | 'pending' | 'succeeded' | 'rejected';
  error: string | null;
  assets: IAsset[];
  asset: IAsset | null;
  summary: FinancialSummary | null;
  maxPortpholiohIdNumber: number | null;
  historicalAggregationByAssetTypeMonthly: ClientAssetHistoryAggregationByAssetType[];
};

const initialState: AssetState = {
  status: 'idle',
  error: null,
  assets: [],
  asset: null,
  summary: null,
  maxPortpholiohIdNumber: null,
  historicalAggregationByAssetTypeMonthly: [],
};

export const fetchAssetsByClientIdAsync = createAppAsyncThunk(
  'assets/fetchAssetsByClientId',
  async ({ id }: { id: string }) => {
    const token = await getToken();
    const twoYearsAgo = new Date(
      new Date().setFullYear(new Date().getFullYear() - 2)
    ).toISOString();
    const today = new Date().toISOString();
    const [assets, summary, historicalAggregationByAssetType] =
      await Promise.all([
        getAssetsByClientId({ clientId: id, token }),
        getClientFinancialSummary({ id, token }),
        getClientAssetHistoryAggregationByAssetType({
          id,
          token,
          freq: 'monthly',
          start_at: twoYearsAgo,
          end_at: today,
        }),
      ]);
    return { assets, summary, historicalAggregationByAssetType };
  }
);

export const createAssetAsync = createAppAsyncThunk(
  'client/createAsset',
  async ({ client_id, data }: { client_id: string; data: IManualInput }) => {
    const token = await getToken();
    const newAsset = await postAsset({ clientId: client_id, data, token });
    return newAsset;
  }
);

export const replaceAssetAsync = createAppAsyncThunk(
  'client/replaceAsset',
  async ({
    client_id,
    id,
    data,
  }: {
    client_id: string;
    id: string;
    data: IManualInput;
  }) => {
    const token = await getToken();
    const response = await putAsset({ clientId: client_id, id, data, token });
    return response;
  }
);

export const removeAssetAsync = createAppAsyncThunk(
  'client/deleteAsset',
  async ({ client_id, id }: { client_id: string; id: string }) => {
    const token = await getToken();
    const response = await deleteAsset({ client_id: client_id, id, token });
    return response;
  }
);

export const clientAssetSlice = createSlice({
  name: 'clientAsset',
  initialState: initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
    dismissError(state) {
      state.error = null;
      state.status = 'succeeded';
    },
    selectAsset(state, action: PayloadAction<IAsset>) {
      state.asset = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAssetsByClientIdAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(fetchAssetsByClientIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.assets = action.payload.assets;
        state.summary = action.payload.summary;
        state.historicalAggregationByAssetTypeMonthly =
          action.payload.historicalAggregationByAssetType;
      })
      .addCase(fetchAssetsByClientIdAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(createAssetAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(createAssetAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.assets = [action.payload, ...state.assets];
      })
      .addCase(createAssetAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(replaceAssetAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(replaceAssetAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const idx = state.assets.findIndex(
          asset => asset.id === action.meta.arg.id
        );
        state.assets = [
          ...state.assets.slice(0, idx),
          action.payload,
          ...state.assets.slice(idx + 1),
        ];
      })
      .addCase(replaceAssetAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(removeAssetAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(removeAssetAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.assets = state.assets.filter(
          asset => asset.id !== action.meta.arg.id
        );
      })
      .addCase(removeAssetAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      });
  },
});

export const selectClientAssetStatus = (state: RootState) =>
  state.clientAsset.status;
export const selectClientAssets = (state: RootState) =>
  state.clientAsset.assets;
export const selectClientAsset = (state: RootState) => state.clientAsset.asset;
export const selectClientAssetError = (state: RootState) =>
  state.clientAsset.error;
export const selectClientFinancialSummary = (state: RootState) =>
  state.clientAsset.summary;

export const selectClientHistoricalAggregationByAssetTypeMonthly = (
  state: RootState
) => state.clientAsset.historicalAggregationByAssetTypeMonthly;
