import { auth } from '../initFirebase';
import { API_URL } from '../config';

export const getToken = async () => {
  const token = await auth.currentUser?.getIdToken();
  if (token) {
    return token;
  } else {
    throw new Error('Failed to get token');
  }
};

export const getTokenWithEmailOrNameAndPassword = async (
  emailOrName: string,
  password: string
) => {
  const payload = emailOrName.includes('@')
    ? { email: emailOrName, password }
    : { name: emailOrName, password };
  const response = await fetch(`${API_URL}/auth/signin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  if (response.ok) {
    const responseJson = await response.json();
    return responseJson;
  }
  throw new Error((await response.json()).message);
};

export const getCustomToken = async (id_token: string) => {
  const response = await fetch(`${API_URL}/auth/custom-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id_token }),
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json();
};
