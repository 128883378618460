import { useAppDispatch } from 'app/hooks';
import { CustomAppBar, Loading } from 'components';
import { useAuth } from 'contexts';
import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const AuthorizedLayout = () => {
  const { currentUser, signOut } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdTokenResult(true).then(idTokenResult => {
        const _isAdmin = idTokenResult.claims.admin as boolean | undefined;
        if (_isAdmin) {
          alert('この画面はお客様のみご利用できます。');
          signOut();
        }
      });
    }
  }, [dispatch, currentUser]);

  if (currentUser === null) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <>
      <CustomAppBar title="" topHref="/" />
      {currentUser === undefined ? <Loading /> : <Outlet />}
    </>
  );
};
