import { Box } from '@mui/material';
import { SelectClient, TabMenus } from 'components';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  familyAssetSlice,
  familyClientSlice,
  familySlice,
  fetchClientsByFamilyIdAsync,
  selectFamily,
  selectFamilyClients,
  selectFamilyClientsStatus,
  selectFamilyStatus,
} from 'features';
import { useEffect } from 'react';

export const FamilyLayout = () => {
  const { pathname } = useLocation();
  const { familyId } = useParams() as { familyId: string };
  const dispatch = useAppDispatch();
  const familyStatus = useAppSelector(selectFamilyStatus);
  const family = useAppSelector(selectFamily);
  const familyClientStatus = useAppSelector(selectFamilyClientsStatus);
  const familyClients = useAppSelector(selectFamilyClients);

  useEffect(() => {
    if (familyClientStatus === 'idle') {
      dispatch(fetchClientsByFamilyIdAsync({ familyId }));
    }
  }, [dispatch, familyClientStatus]);

  useEffect(() => {
    if (familyStatus === 'succeeded') {
      dispatch(familySlice.actions.reset());
      dispatch(familyClientSlice.actions.reset());
      dispatch(familyAssetSlice.actions.reset());
    }
  }, [dispatch, familyId]);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          borderBottom: 'solid 1px #ddd',
          alignItems: 'center',
          mt: { xs: 0, sm: 0.5 },
        }}
      >
        {family && <SelectClient clients={familyClients} family={family} />}
        <TabMenus initialTabValue={pathname} viewType="family" />
      </Box>
      <Outlet />
    </>
  );
};
