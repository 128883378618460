import {
  FinancialProductDetail,
  FinancialProductListItem,
  WithId,
  WithTimestamp,
} from 'types';
import { API_URL } from '../config';

export const getFinancialProducts = async (token: string) => {
  const response = await fetch(`${API_URL}/financial-products`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<
    WithTimestamp<WithId<FinancialProductListItem[]>>
  >;
};

export const getFinancialProduct = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/financial-products/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<
    WithTimestamp<WithId<FinancialProductDetail>>
  >;
};
