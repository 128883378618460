import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  NewAPIMentoringSheet,
  NewAPIMentoringSheetWithServerValues,
  PostClientMentoringSheetPayload,
  WithId,
  WithTimestamp,
} from 'types';
import type { RootState } from '../../app/store';
import { createAppAsyncThunk } from '../../app/withTypes';
import { getToken } from 'api';
import {
  deleteClientMentoringSheet,
  getClientMentoringSheetsByClientId as getClientMentoringSheetsByClientId,
  patchClientMentoringSheet,
  postClientMentoringSheet,
  putClientMentoringSheet,
} from 'api';

type MentoringSheetState = {
  listStatus: 'idle' | 'pending' | 'succeeded' | 'rejected';
  listStatusCode: number | null;
  listError: string | null;
  mentoringSheets: WithTimestamp<WithId<NewAPIMentoringSheet>>[];
  detailStatus: 'idle' | 'pending' | 'succeeded' | 'rejected';
  detailStatusCode: number | null;
  detailError: string | null;
  mentoringSheet: WithTimestamp<WithId<NewAPIMentoringSheet>> | null;
};

const initialState: MentoringSheetState = {
  listStatus: 'idle',
  listStatusCode: null,
  listError: null,
  mentoringSheets: [],
  detailStatus: 'idle',
  detailStatusCode: null,
  detailError: null,
  mentoringSheet: null,
};

export const fetchMentoringSheetsByClientIdAsync = createAppAsyncThunk(
  'mentoringSheet/fetchMentoringSheets',
  async ({ id }: { id: string }) => {
    const token = await getToken();
    const response = await getClientMentoringSheetsByClientId({ id, token });
    return response;
  }
);

export const createMentoringSheetAsync = createAppAsyncThunk(
  'mentoringSheet/createMentoringSheet',
  async ({
    client_id,
    data,
  }: {
    client_id: string;
    data: PostClientMentoringSheetPayload;
  }) => {
    const token = await getToken();
    const response = await postClientMentoringSheet({ client_id, data, token });
    return response;
  }
);

export const replaceMentoringSheetAsync = createAppAsyncThunk(
  'mentoringSheet/replaceMentoringSheet',
  async ({
    client_id,
    id,
    data,
  }: {
    client_id: string;
    id: string;
    data: NewAPIMentoringSheet;
  }) => {
    const token = await getToken();
    const response = await putClientMentoringSheet({
      client_id,
      id,
      data,
      token,
    });
    return response;
  }
);

export const removeMentoringSheetAsync = createAppAsyncThunk(
  'mentoringSheet/deleteMentoringSheet',
  async ({ client_id, id }: { client_id: string; id: string }) => {
    const token = await getToken();
    await deleteClientMentoringSheet({ client_id, id, token });
    return;
  }
);

export const mentoringSheetSlice = createSlice({
  name: 'mentoringSheet',
  initialState: initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
    selectMentoringSheet(
      state,
      action: PayloadAction<NewAPIMentoringSheetWithServerValues>
    ) {
      state.mentoringSheet = action.payload;
    },
    dismissDetailError(state) {
      state.detailError = null;
      state.detailStatus = 'idle';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMentoringSheetsByClientIdAsync.pending, (state, action) => {
        state.listStatus = 'pending';
      })
      .addCase(
        fetchMentoringSheetsByClientIdAsync.fulfilled,
        (state, action) => {
          state.listStatus = 'succeeded';
          state.detailStatus = 'succeeded';
          state.mentoringSheets = action.payload;
          state.mentoringSheet = action.payload[0] ?? null;
        }
      )
      .addCase(
        fetchMentoringSheetsByClientIdAsync.rejected,
        (state, action) => {
          state.listStatus = 'rejected';
          state.listError = action.error.message ?? 'エラーが発生しました';
        }
      )
      .addCase(createMentoringSheetAsync.pending, (state, action) => {
        state.detailStatus = 'pending';
      })
      .addCase(createMentoringSheetAsync.fulfilled, (state, action) => {
        state.detailStatus = 'succeeded';
        state.mentoringSheets = [action.payload, ...state.mentoringSheets];
        state.mentoringSheet = action.payload;
      })
      .addCase(createMentoringSheetAsync.rejected, (state, action) => {
        state.detailStatus = 'rejected';
        state.detailError = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(replaceMentoringSheetAsync.pending, (state, action) => {
        state.detailStatus = 'pending';
      })
      .addCase(replaceMentoringSheetAsync.fulfilled, (state, action) => {
        state.detailStatus = 'succeeded';
        const idx = state.mentoringSheets.findIndex(
          mentoringSheet => mentoringSheet.id === action.meta.arg.id
        );
        state.mentoringSheets = [
          ...state.mentoringSheets.slice(0, idx),
          action.payload,
          ...state.mentoringSheets.slice(idx + 1),
        ];
        state.mentoringSheet = action.payload;
      })
      .addCase(replaceMentoringSheetAsync.rejected, (state, action) => {
        state.detailStatus = 'rejected';
        state.detailError = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(removeMentoringSheetAsync.pending, (state, action) => {
        state.detailStatus = 'pending';
      })
      .addCase(removeMentoringSheetAsync.fulfilled, (state, action) => {
        state.detailStatus = 'succeeded';
        state.mentoringSheets = state.mentoringSheets.filter(
          mentoringSheet => mentoringSheet.id !== action.meta.arg.id
        );
        state.mentoringSheet = state.mentoringSheets[0] ?? null;
      })
      .addCase(removeMentoringSheetAsync.rejected, (state, action) => {
        state.detailStatus = 'rejected';
        state.detailError = action.error.message ?? 'エラーが発生しました';
      });
  },
});

export const selectMentoringSheetsStatus = (state: RootState) =>
  state.mentoringSheet.listStatus;
export const selectMentoringSheetsError = (state: RootState) =>
  state.mentoringSheet.listError;
export const selectMentoringSheets = (state: RootState) =>
  state.mentoringSheet.mentoringSheets;

export const selectMentoringSheetStatus = (state: RootState) =>
  state.mentoringSheet.detailStatus;
export const selectMentoringSheetError = (state: RootState) =>
  state.mentoringSheet.detailError;
export const selectMentoringSheet = (state: RootState) =>
  state.mentoringSheet.mentoringSheet;
