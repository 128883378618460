import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Paper,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import bgImg from 'assets/loginbg.jpeg';
import { MaintenanceInfo } from 'components/MaintenanceInfo';
import { useAuth } from 'contexts';
import { useRedirectIfSignedIn } from 'hooks';
import { LogoTop } from 'parts/LogoTop';
import { useState } from 'react';

export const LinkToForgotPassword = ({ sx }: { sx?: SxProps }) => (
  <Box sx={{ display: 'flex', ...sx }} justifyContent="center">
    <Link href="/reset-password" variant="caption">
      パスワードをお忘れの場合
    </Link>
  </Box>
);

export const LinKToHelp = ({ sx }: { sx?: SxProps }) => (
  <Box sx={{ display: 'flex', ...sx }} justifyContent="center">
    <Link
      href="https://knowledge.pragmaworks.co.jp/アカウント-セキュリティ"
      variant="caption"
    >
      お困りですか？
    </Link>
  </Box>
);

export const SignInPage = () => {
  const { signIn, status, error } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  useRedirectIfSignedIn();
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        backgroundImage: `url(${bgImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item>
          <Paper
            elevation={3}
            sx={{ width: 300, p: 5 }}
            component="form"
            onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              await signIn(email, password);
            }}
          >
            <LogoTop />
            <Box
              sx={{ textAlign: 'center', mt: 1, mb: 5 }}
              dangerouslySetInnerHTML={{ __html: 'ポートフォリ王 &reg;' }}
            />
            <Box sx={{ mb: 2 }}>
              <TextField
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(event.target.value);
                }}
                variant="outlined"
                value={email}
                size="small"
                label="メールアドレスもしくは顧客番号"
                fullWidth
                type="text"
              />
            </Box>
            <TextField
              label="パスワード"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
              }}
              variant="outlined"
              value={password}
              size="small"
              fullWidth
              type="password"
              autoComplete="current-password"
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Button
                color="secondary"
                variant="contained"
                size="large"
                type="submit"
                disabled={status === 'loading'}
              >
                {status === 'loading' ? (
                  <CircularProgress size={'1rem'} sx={{ color: '#fff' }} />
                ) : (
                  'ログイン'
                )}
              </Button>
            </Box>
            {error && (
              <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            <MaintenanceInfo />
            <LinkToForgotPassword sx={{ mt: 4 }} />
            <LinKToHelp sx={{ mt: 1 }} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
