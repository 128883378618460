import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import {
  clientSlice,
  familySlice,
  financialProductSlice,
  mentoringSheetFileSlice,
  mentoringSheetSlice,
  clientAssetSlice,
  familyAssetSlice,
  clientInvestmentStrategySlice,
  investmentStrategySlice,
} from '../features';
import { createLogger } from 'redux-logger';
import { familyClientSlice } from 'features/family-clients/familyClientSlice';

const logger = createLogger({});
export const store = configureStore({
  reducer: {
    client: clientSlice.reducer,
    familyClient: familyClientSlice.reducer,
    clientAsset: clientAssetSlice.reducer,
    family: familySlice.reducer,
    familyAsset: familyAssetSlice.reducer,
    financialProduct: financialProductSlice.reducer,
    mentoringSheet: mentoringSheetSlice.reducer,
    mentoringSheetFile: mentoringSheetFileSlice.reducer,
    clientInvestmentStrategy: clientInvestmentStrategySlice.reducer,
    investmentStrategy: investmentStrategySlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    process.env.REACT_APP_FIREBASE_PROJECT_ID === 'pw-banto-portfolio-dev'
      ? getDefaultMiddleware({
          serializableCheck: false,
        }).concat(logger)
      : getDefaultMiddleware({
          serializableCheck: false,
        }),
});

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
