import { Timestamp, collection, getDocs, query } from 'firebase/firestore';
import { db } from 'initFirebase';

type ExchangeRateLatestStore = {
  exchangeRateLast: number;
  exchangeRateRd: number;
  evaluatedAt: Timestamp;
  updatedAt?: Timestamp;
};

export type ExchangeRateLatest = {
  currencyCode: string;
  exchangeRateLast: number;
  exchangeRateRd: number;
  evaluatedAt: Date;
  updatedAt?: Date;
};

export const listExchangeRateLatests = async () => {
  const q = query(collection(db, 'exchangeRatesLatest'));
  const querySnapshot = await getDocs(q);
  const exchangeRateLatests = querySnapshot.docs.map(doc => {
    const storeData = doc.data() as ExchangeRateLatestStore;
    const data: ExchangeRateLatest = {
      currencyCode: doc.id,
      exchangeRateLast: storeData.exchangeRateLast,
      exchangeRateRd: storeData.exchangeRateRd,
      evaluatedAt: storeData.evaluatedAt.toDate(),
      updatedAt: storeData.updatedAt?.toDate(),
    };
    return data;
  });
  return exchangeRateLatests;
};