import { Loading } from 'components/Loading';
import { MentoringSheetForm } from 'components/MentoringSheetForm';
import { SelectSheet, AlignCenterGrid } from 'components';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  fetchMentoringSheetFileAsync,
  fetchMentoringSheetFilesAsync,
  mentoringSheetSlice,
  removeMentoringSheetFileAsync,
  replaceMentoringSheetAsync,
  selectMentoringSheet,
  selectMentoringSheetError,
  selectMentoringSheetFiles,
  selectMentoringSheetFileStatus,
  selectMentoringSheets,
  selectMentoringSheetsStatus,
  selectMentoringSheetStatus,
  uploadMentoringSheetFileAsync,
} from 'features';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext } from 'contexts';
import { NewAPIMentoringSheet, WithId, WithTimestamp } from 'types';
import { ErrorSnackbar } from 'components/Snackbar/Snackbars';

export const MentoringPage = () => {
  const dispatch = useAppDispatch();
  const { clientId } = useParams() as { clientId: string };
  const mentoringSheetsStatus = useAppSelector(selectMentoringSheetsStatus);
  const mentoringSheets = useAppSelector(selectMentoringSheets);
  const mentoringSheet = useAppSelector(selectMentoringSheet);
  const { draftMentoringSheet, setDraftMentoringSheet } = useAppContext();

  const mentoringSheetFileStatus = useAppSelector(
    selectMentoringSheetFileStatus
  );
  const mentoringSheetFiles = useAppSelector(selectMentoringSheetFiles);
  const mentoringSheetDetailStatus = useAppSelector(selectMentoringSheetStatus);
  const mentoringSheetDetailError = useAppSelector(selectMentoringSheetError);

  useEffect(() => {
    setDraftMentoringSheet(mentoringSheet);
  }, [mentoringSheet]);

  useEffect(() => {
    const mentoring_sheet_id = mentoringSheet?.id;
    if (mentoring_sheet_id) {
      dispatch(
        fetchMentoringSheetFilesAsync({
          client_id: clientId,
          mentoring_sheet_id: mentoring_sheet_id,
        })
      );
    }
  }, [dispatch, mentoringSheet?.id]);

  const uploadMentoringSheetFile = async (file: File) => {
    if (!draftMentoringSheet) {
      return;
    }
    dispatch(
      uploadMentoringSheetFileAsync({
        client_id: clientId,
        mentoring_sheet_id: draftMentoringSheet.id,
        file,
      })
    );
  };
  const getMentoringSheetFileDownloadLink = async (file_id: string) => {
    const data = await dispatch(
      fetchMentoringSheetFileAsync({
        client_id: clientId,
        mentoring_sheet_id: draftMentoringSheet?.id ?? '',
        file_id,
      })
    ).unwrap();
    return data.download_url;
  };
  const removeMentoringSheetFile = async (file_id: string) => {
    if (!draftMentoringSheet) {
      return;
    }
    dispatch(
      removeMentoringSheetFileAsync({
        client_id: clientId,
        mentoring_sheet_id: draftMentoringSheet.id,
        file_id,
      })
    );
    return;
  };

  if (mentoringSheetsStatus === 'pending') {
    return <Loading />;
  }
  if (
    mentoringSheetsStatus === 'succeeded' &&
    mentoringSheets.length > 0 &&
    !draftMentoringSheet
  ) {
    return <Loading />;
  }

  if (mentoringSheetsStatus === 'rejected') {
    return <div>エラーが発生しました。担当にお問い合わせください。</div>;
  }

  if (!draftMentoringSheet) {
    return (
      <AlignCenterGrid>
        <Box display="flex" sx={{ px: 2 }}>
          <Typography>シートがありません</Typography>
        </Box>
      </AlignCenterGrid>
    );
  }

  return (
    <AlignCenterGrid>
      <Box sx={{ px: 2 }}>
        <SelectSheet
          sheets={mentoringSheets}
          sheet={draftMentoringSheet}
          selectSheet={(sheet: WithTimestamp<WithId<NewAPIMentoringSheet>>) => {
            dispatch(mentoringSheetSlice.actions.selectMentoringSheet(sheet));
          }}
        />
      </Box>
      {mentoringSheets.length > 0 && draftMentoringSheet ? (
        <MentoringSheetForm
          draftSheet={draftMentoringSheet}
          setDraftSheet={setDraftMentoringSheet}
          save={() => {
            const { id, ...data } = draftMentoringSheet;
            dispatch(
              replaceMentoringSheetAsync({
                id,
                client_id: clientId,
                data,
              })
            );
          }}
          mentoringSheetFiles={mentoringSheetFiles}
          getMentoringSheetDownloadLink={getMentoringSheetFileDownloadLink}
          uploadMentoringSheetFile={uploadMentoringSheetFile}
          removeMentoringSheetFile={removeMentoringSheetFile}
        />
      ) : (
        <div>シートがまだ作成されていません。担当にお問い合わせください。</div>
      )}
      {mentoringSheetDetailError && (
        <ErrorSnackbar
          message={mentoringSheetDetailError}
          open={mentoringSheetDetailStatus === 'rejected'}
          onClose={() =>
            dispatch(mentoringSheetSlice.actions.dismissDetailError())
          }
        />
      )}
    </AlignCenterGrid>
  );
};
