import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getClientInvestmentStrategies,
  getToken,
  putClientInvestmentStrategy,
} from 'api';
import { RootState } from 'app/store';
import { ClientInvestmentStrategy, WithId } from 'types';

export const fetchClientInvestmentStrategiesAsync = createAsyncThunk(
  'client-investment-strategy/fetchClientInvestmentStrategies',
  async ({ client_id }: { client_id: string }) => {
    const token = await getToken();
    const response = await getClientInvestmentStrategies({ client_id, token });
    return response;
  }
);

export const replaceClientInvestmentStrategyAsync = createAsyncThunk(
  'client-investment-strategy/replaceClientInvestmentStrategy',
  async ({
    client_id,
    id,
    data,
  }: {
    client_id: string;
    id: string;
    data: ClientInvestmentStrategy;
  }) => {
    const token = await getToken();
    const response = await putClientInvestmentStrategy({
      client_id,
      id,
      token,
      data,
    });
    return response;
  }
);

type ClientInvestmentStrategyState = {
  clientInvestmentStrategies: WithId<ClientInvestmentStrategy>[];
  status: 'idle' | 'pending' | 'succeeded' | 'rejected';
  error: string | null;
};

const initialState: ClientInvestmentStrategyState = {
  clientInvestmentStrategies: [],
  status: 'idle',
  error: null,
};

export const clientInvestmentStrategySlice = createSlice({
  name: 'client-investment-strategy',
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchClientInvestmentStrategiesAsync.pending, state => {
        state.status = 'pending';
      })
      .addCase(
        fetchClientInvestmentStrategiesAsync.fulfilled,
        (state, action) => {
          state.status = 'succeeded';
          state.clientInvestmentStrategies = action.payload;
        }
      )
      .addCase(
        fetchClientInvestmentStrategiesAsync.rejected,
        (state, action) => {
          state.status = 'rejected';
          state.error = action.error.message ?? 'エラーが発生しました';
        }
      )
      .addCase(replaceClientInvestmentStrategyAsync.pending, state => {
        state.status = 'pending';
      })
      .addCase(
        replaceClientInvestmentStrategyAsync.fulfilled,
        (state, action) => {
          state.status = 'succeeded';
          const ix = state.clientInvestmentStrategies.findIndex(
            strategy => strategy.id === action.payload.id
          );
          state.clientInvestmentStrategies = [
            ...state.clientInvestmentStrategies.slice(0, ix),
            action.payload,
            ...state.clientInvestmentStrategies.slice(ix + 1),
          ];
        }
      )
      .addCase(
        replaceClientInvestmentStrategyAsync.rejected,
        (state, action) => {
          state.status = 'rejected';
          state.error = action.error.message ?? 'エラーが発生しました';
        }
      );
  },
});

export const selectClientInvestmentStrategyStatus = (state: RootState) =>
  state.clientInvestmentStrategy.status;

export const selectClientInvestmentStrategies = (state: RootState) =>
  state.clientInvestmentStrategy.clientInvestmentStrategies;
export const selectClientInvestmentStrategyError = (state: RootState) =>
  state.clientInvestmentStrategy.error;
